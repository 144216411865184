// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-company-js": () => import("./../../../src/pages/about-company.js" /* webpackChunkName: "component---src-pages-about-company-js" */),
  "component---src-pages-about-company-ua-js": () => import("./../../../src/pages/about-company.ua.js" /* webpackChunkName: "component---src-pages-about-company-ua-js" */),
  "component---src-pages-green-tariff-js": () => import("./../../../src/pages/green-tariff.js" /* webpackChunkName: "component---src-pages-green-tariff-js" */),
  "component---src-pages-green-tariff-ua-js": () => import("./../../../src/pages/green-tariff.ua.js" /* webpackChunkName: "component---src-pages-green-tariff-ua-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-ua-js": () => import("./../../../src/pages/index.ua.js" /* webpackChunkName: "component---src-pages-index-ua-js" */),
  "component---src-pages-services-auto-js": () => import("./../../../src/pages/services-auto.js" /* webpackChunkName: "component---src-pages-services-auto-js" */),
  "component---src-pages-services-auto-ua-js": () => import("./../../../src/pages/services-auto.ua.js" /* webpackChunkName: "component---src-pages-services-auto-ua-js" */),
  "component---src-pages-services-business-js": () => import("./../../../src/pages/services-business.js" /* webpackChunkName: "component---src-pages-services-business-js" */),
  "component---src-pages-services-business-ua-js": () => import("./../../../src/pages/services-business.ua.js" /* webpackChunkName: "component---src-pages-services-business-ua-js" */),
  "component---src-pages-services-home-js": () => import("./../../../src/pages/services-home.js" /* webpackChunkName: "component---src-pages-services-home-js" */),
  "component---src-pages-services-home-ua-js": () => import("./../../../src/pages/services-home.ua.js" /* webpackChunkName: "component---src-pages-services-home-ua-js" */),
  "component---src-pages-services-reserve-js": () => import("./../../../src/pages/services-reserve.js" /* webpackChunkName: "component---src-pages-services-reserve-js" */),
  "component---src-pages-services-reserve-ua-js": () => import("./../../../src/pages/services-reserve.ua.js" /* webpackChunkName: "component---src-pages-services-reserve-ua-js" */),
  "component---src-templates-blog-inner-js": () => import("./../../../src/templates/blog-inner.js" /* webpackChunkName: "component---src-templates-blog-inner-js" */),
  "component---src-templates-blog-inner-ua-js": () => import("./../../../src/templates/blog-inner.ua.js" /* webpackChunkName: "component---src-templates-blog-inner-ua-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-ua-js": () => import("./../../../src/templates/blog.ua.js" /* webpackChunkName: "component---src-templates-blog-ua-js" */),
  "component---src-templates-project-category-js": () => import("./../../../src/templates/project-category.js" /* webpackChunkName: "component---src-templates-project-category-js" */),
  "component---src-templates-project-category-ua-js": () => import("./../../../src/templates/project-category.ua.js" /* webpackChunkName: "component---src-templates-project-category-ua-js" */),
  "component---src-templates-projects-inner-js": () => import("./../../../src/templates/projects-inner.js" /* webpackChunkName: "component---src-templates-projects-inner-js" */),
  "component---src-templates-projects-inner-ua-js": () => import("./../../../src/templates/projects-inner.ua.js" /* webpackChunkName: "component---src-templates-projects-inner-ua-js" */),
  "component---src-templates-video-category-js": () => import("./../../../src/templates/video-category.js" /* webpackChunkName: "component---src-templates-video-category-js" */),
  "component---src-templates-video-category-ua-js": () => import("./../../../src/templates/video-category.ua.js" /* webpackChunkName: "component---src-templates-video-category-ua-js" */),
  "component---src-templates-video-home-js": () => import("./../../../src/templates/video-home.js" /* webpackChunkName: "component---src-templates-video-home-js" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */)
}

